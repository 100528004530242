/* You can add global styles to this file, and also import other style files */
/*@import '~@angular/material/prebuilt-themes/indigo-pink.css';*/
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@keyframes flyToCart {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    50% {
      transform: translate(50vw, -50vh) scale(0.5);
    }
    100% {
      opacity: 0;
      transform: translate(100vw, -100vh) scale(0);
    }
  }
  
  .flying {
    position: absolute;
    z-index: 9999;
    animation: flyToCart 0.7s ease-in-out forwards;
  }
  

  .navbar-nav .active {
    color: black !important;
    border-radius: 5px;
    font-size: large;
    background-color: #f2f2f2;
    transition: 0.1s;
  }
  
  .navbar-nav .nav-item .nav-link {
    transition: color 0.3s;
    padding: 5px;
  }
  
  .navbar-nav .nav-item .nav-link:hover {
    color: #131313;  /* Hover color for non-active links */
  }
  button {
    display: inline-flex;
    align-items: center;  /* Vertically centers the content */
    justify-content: center;  /* Horizontally centers the content */
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    border: 0;
    color: black;
    background: #F8F9FA;
    transition: 0.3s;
  }
  button:hover{
    color:gray;
    border-radius: 20px;
    transition: 0.3s;
  }
  button:active {
    transform: scale(0.95);
    transition: 0.3s;
  }
  button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .spinner {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 3px solid rgba(89, 83, 83, 1);
    border-radius: 50%;
    border-top-color: white;
    color: gray;
    animation: spin 1s ease infinite;
    margin-right: 8px;
  }
 
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
  .ngb-dp-weekday{
    color: #666b6c!important;
  }
  .btn-link{
    color: black;
  }
  .btn-link:hover{
    color: gray;
  }
  .spantransition {
    display: inline-block;
    transition: opacity 3s ease, transform 3s ease;
  }
  
  .spinnerplace {
    display: flex;
    align-items: center;
  }
  


  

  
  